import React, { useEffect, useMemo, useState } from 'react'
import { PopupButton } from 'react-calendly'
import bullHornIcon from '../Assets/Svg/bullhorn-variant.svg'
import 'animate.css'
import HeroImg from '../Assets/Img/hero_image.png'

import { Link } from 'react-router-dom'

const HeroNew = () => {
  const testimonials = useMemo(
    () => [
      'About 1.5 million Nigerian students are unable to secure admission into tertiary institutions every year',
      '600,000+ Nigerians graduate every year, largely without industry applicable skills',
      'The Universities available cannot meet up to the demands of accommodation and equipping young africans',
    ],
    []
  )

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)
  const [showStartHereAnimation, setShowStartHereAnimation] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true)
      setTimeout(() => {
        setCurrentTestimonialIndex((prevIndex) =>
          prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        )
        setIsAnimating(false)
        if (currentTestimonialIndex === testimonials.length - 1) {
          setShowStartHereAnimation(false)
        }
      }, 500)
    }, 5000)

    return () => clearInterval(interval)
  }, [testimonials, currentTestimonialIndex])

  useEffect(() => {
    const restartDelay = setTimeout(() => {
      setShowStartHereAnimation(true)
    }, 3000)

    return () => clearTimeout(restartDelay)
  }, [showStartHereAnimation])

  return (
    <div className="md:py-16 py-6 w-11/12 container mx-auto">
      <div className="flex flex-col md:flex-row items-start justify-between gap-10">
        <div className="w-full md:w-1/2 ">
          <h1 className="text-black text-[50px] md:text-[64px] font-semibold font-clash leading-[60px] md:leading-[84px]">
            Meet the
            <br />
            <span className="text-indigo-500">People </span>
            <span className="text-indigo-500">Advancing </span>
            <span className="text-indigo-500"> Learning</span> in Africa.
          </h1>
          <p className="text-gray-500 text-xl font-medium font-inter leading-[30px]">
            In a continent rich with opportunities and faced with challenges,
            Unify is on a mission to make quality education accessible to young
            Africans.
          </p>

          <div className="flex items-center gap-5 my-2.5">
            <PopupButton
              className="w-max h-10 px-4 py-2 bg-zinc-900 rounded justify-start items-center gap-1 inline-flex text-gray-50 text-base font-medium font-inter leading-normal"
              text="Get in touch"
              url="https://docs.google.com/forms/d/e/1FAIpQLSfvBm8Tag_vaiYpYZOlr8EpB-zvCR__bQDxhYHAFaeVeY9C5w/viewform?usp=sf_link"
              rootElement={document.getElementById('root')}
            />
          </div>
          {showStartHereAnimation && (
            <div className="w-full md:w-max text-black my-10 flex items-center justify-center">
              <div className="flex flex-col gap-5">
                <div
                  className={`w-full h-max md:h-20 p-4 bg-white rounded-lg shadow border border-gray-200 justify-start items-start gap-3 inline-flex ${
                    isAnimating
                      ? 'animate__animated animate__zoomOut'
                      : 'animate__animated animate__zoomIn'
                  }`}
                >
                  <div className="w-[31px] h-[31px] bg-white rounded-full shadow border border-gray-200 flex items-center justify-center">
                    <img
                      src={bullHornIcon}
                      alt="bull-horn"
                      className="w-[15px] h-[15px] "
                    />
                  </div>
                  <div className="w-full md:w-[452px] text-slate-700 text-base font-medium font-inter leading-normal">
                    {testimonials[currentTestimonialIndex]}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full md:w-1/2 ">
          <img src={HeroImg} alt="hero img" />
        </div>
      </div>
    </div>
  )
}

export default HeroNew
