import React from 'react'
import Tutorial from '../Components/Tutorial'
import Unifyforwho from '../Components/Unifyforwho'
import HeroNew from '../Components/HeroNew.js'
import UnifyCampusSection from '../Components/UnifyCampusSection.js'

const home = () => {
  return (
    <div className="pt-20 text-black">
      <HeroNew />
      <Tutorial />
      <Unifyforwho />
      <UnifyCampusSection />
    </div>
  )
}

export default home
