import React, { useState } from 'react'
import ios from '../Assets/Svg/ios.svg'
import android from '../Assets/Svg/android.svg'
import combo from '../Assets/Svg/combo.svg'
import logowhite from '../Assets/Svg/logowhite.svg'
import ig from '../Assets/Svg/ig.svg'
import fb from '../Assets/Svg/fb.svg'
import twitter from '../Assets/Svg/twitter.svg'
import { FaSquareXTwitter, FaSquareInstagram } from 'react-icons/fa6'
import { FaYoutube, FaLinkedin, FaFacebook } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { PopupButton } from 'react-calendly'
import Forms from './Desktop/Forms'

const Footer = () => {
  const [openForm, setOpenForm] = useState(false)

  return (
    <div className="grid">
      <div className="bg-[#1D0633]  z-10 py-10 text-white">
        <div className="w-11/12 container mx-auto ">
          <div className="grid md:grid-cols-12 items-center   py-20">
            <div className="mt-10 md:mt-0 col-span-5">
              <div>
                <img src={logowhite} alt="logowhite" />
              </div>
              <p className="mt-5 mb-16 pr-28">People Advancing Learning</p>
              <p className="mt-2 mb-4">Join our Community</p>

              <div className="flex border border-[#311849] p-[3px] md:w-[380px]">
                <input
                  type="text"
                  placeholder="Enter Your email"
                  className="flex-1 outline-none border-none p-2 bg-transparent"
                />
                <button className="border py-1 rounded px-2">Submit</button>
              </div>
            </div>
            <div className="md:pl-24 col-span-7 md:order-last order-first">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                <div>
                  <h1>Links</h1>
                  <div className="text-left mt-8 text-[#ECD8FF] text-base flex flex-col space-y-5">
                    <a
                      href="https://goverticul.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Verticul
                    </a>
                    <PopupButton
                      className="text-left"
                      url="https://docs.google.com/forms/d/e/1FAIpQLSfvBm8Tag_vaiYpYZOlr8EpB-zvCR__bQDxhYHAFaeVeY9C5w/viewform?usp=sf_link"
                      rootElement={document.getElementById('root')}
                      text="Get in touch"
                    />

                    <a
                      href="https://unify-prod-blog.azurewebsites.net/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Blog
                    </a>
                  </div>
                </div>

                <div>
                  <h1>About</h1>
                  <div className="mt-8 text-[#ECD8FF] text-base flex flex-col space-y-5">
                    <Link to="/about"> About us </Link>
                    <Link to="/faqs"> FAQs</Link>
                    <Link to="/tandc"> Terms & Conditions </Link>
                    <Link to="privacy"> Privacy policy</Link>
                  </div>
                </div>
                <div>
                  <h1>Socials</h1>
                  <div className="hidden md:flex mt-8 text-[#ECD8FF] text-base  flex-col space-y-5">
                    <a href="https://www.instagram.com/unify_ng/">
                      {' '}
                      Instagram{' '}
                    </a>

                    <a href="https://web.facebook.com/unifyng/">Facebook</a>

                    <a href="https://twitter.com/unify_ng/"> X (Twitter) </a>
                    <a href="https://www.youtube.com/@unify1003"> Youtube </a>
                    <a href="https://www.linkedin.com/company/unifyng/ ">
                      {' '}
                      LinkedIn{' '}
                    </a>
                  </div>
                  <div className="md:hidden mt-8 flex items-center space-x-9">
                    <a href="https://web.facebook.com/unifyng/">
                      {' '}
                      <FaFacebook />{' '}
                    </a>
                    <a href="https://www.instagram.com/unify_ng/">
                      {' '}
                      <FaSquareInstagram />{' '}
                    </a>
                    <a href="https://twitter.com/unify_ng/">
                      {' '}
                      <FaSquareXTwitter />{' '}
                    </a>
                    <a href="https://www.youtube.com/@unify1003">
                      {' '}
                      <FaYoutube />{' '}
                    </a>
                    <a href="https://www.linkedin.com/company/unifyng/ ">
                      {' '}
                      <FaLinkedin />{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openForm && (
        <Forms
          handleClose={() => {
            setOpenForm(!openForm)
          }}
        />
      )}
    </div>
  )
}

export default Footer
