import React, { useState } from 'react'
import productUnifyLogo from '../../Assets/Svg/verticul_logo.svg'
import logo from '../../Assets/Svg/new360Logo.svg'
import arrowright from '../../Assets/Svg/arrowright.svg'
import forms from '../../Assets/Svg/forms.svg'
import './style.css'
import Forms from './Forms'

const Product = () => {
  const [openForm, setOpenForm] = useState(false)

  return (
    <div className="fixed inset-x-0 top-10 pt-12 bg-[#fafafa]">
      <div className="header-productInfo py-2">
        <div className="header container py-3 w-11/12 mx-auto grid grid-cols-12">
          <div className="col-span-5 text-[20px] font-medium">
            Products by Unify
          </div>
          <div className="col-span-7">
            <div className="flex items-stretch gap-9 mb-4">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <img
                    src={productUnifyLogo}
                    alt=""
                    className="w-[52px] h-5 -m-1"
                  />
                  <p>Verticul</p>
                </div>
                <div className="flex items-center space-x-2 mb-8">
                  <a
                    href="https://goverticul.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2"
                  >
                    <p>Visit site</p>
                    <img src={arrowright} alt="Arrow right" />
                  </a>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-2">
                    <img src={logo} className="w-[52px] h-5 -m-1" alt="" />
                    <p>Plearnty By Unify</p>
                  </div>
                  <div className="flex items-center space-x-2 mb-8">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://plearnty.unifyedu.ng/"
                    >
                      Visit site
                    </a>
                    <img src={arrowright} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openForm && (
        <Forms
          handleClose={() => {
            setOpenForm(!openForm)
          }}
        />
      )}
    </div>
  )
}

export default Product
