import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'
import logo from '../../Assets/Svg/logo.svg'
import ArrowDown from '../../Assets/Svg/ArrowDown.svg'
import Product from './Product'
import { PopupButton } from 'react-calendly'

const Header = () => {
  const [productInfo, setProductInfo] = useState(false)

  return (
    <div className="fixed z-10 bg-[#FAFAFA] inset-x-0 top-0">
      <div className="header container py-3 w-11/12 mx-auto flex justify-between items-center">
        <div>
          <a href="/">
            <img src={logo} alt="" className="w-full h-full object-contain" />
          </a>
        </div>
        <div className=" flex justify-between items-center space-x-8">
          <NavLink
            onClick={() => setProductInfo(false)}
            activeClassName="active_link"
            className={`text-[#575765] text-base`}
            to="/home"
          >
            Home
          </NavLink>

          <div
            className={`${
              productInfo ? 'text-[#1B1B22]' : ''
            }  text-[#575765] cursor-pointer flex items-center text-base product_wrapper`}
          >
            Products
            <img src={ArrowDown} className="ml-2" alt="" />
            <div className="product_component pt-10">
              <Product />
            </div>
          </div>
          <NavLink
            onClick={() => setProductInfo(false)}
            activeClassName="active_link"
            className={`text-[#575765] text-base `}
            to="/faqs"
          >
            FAQs
          </NavLink>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default Header
