import React from 'react'
import YouTube from 'react-youtube'

const YoutubeVideo = () => {
  const videoId = 'LyoMDAJ4xDo' // Replace with your video ID

  const opts = {
    height: '400',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  }

  return (
    <div className="w-full ring-3 ring-white rounded">
      <YouTube videoId={videoId} opts={opts} />
    </div>
  )
}

export default YoutubeVideo
