import React from 'react'
import './Component-styles.css'
import YoutubeVideo from './YoutubeVideo'

const Tutorial = () => {
  return (
    <div className="relative">
      <div className="bg-[#F1EBE3]  md:h-[320px] h-[200px]"></div>
      <div className="transform md:-translate-y-[200px] -translate-y-[105px] w-11/12 md:w-7/12 container mx-auto ">
        <YoutubeVideo />
      </div>
    </div>
  )
}

export default Tutorial
