import React from 'react'
import plenty_coin from '../Assets/Img/Plearnty_coin.svg'

const UnifyCampusSection = () => {
  return (
    <div className="py-5 px-10 flex md:flex-row flex-col items-center gap-5 my-10">
      <div className="w-full md:w-1/2">
        <img src={plenty_coin} alt="revolving globe" />
      </div>
      <div className="w-full md:w-1/2">
        <span className="text-indigo-500 text-base md:text-lg font-medium font-inter leading-7">
          Plearnty By Unify
        </span>
        <h2 className="md:max-w-[561px] text-gray-900 text-3xl md:text-5xl font-semibold font-inter leading-[45px] md:leading-[60px]">
          This is Proof that Learning Pays!{' '}
        </h2>

        <p className="md:max-w-[511px] text-slate-700 py-2.5 text-lg md:text-xl font-normal font-inter leading-[30px]">
          Play, Learn, and Earn, Plenty...on the quiz and community platform
          that truly rewards undergraduates in Nigeria!
        </p>
      </div>
    </div>
  )
}

export default UnifyCampusSection
