import React from 'react'
import verticul_logo from '../Assets/Svg/verticul_logo.svg'
import 'animate.css'
import './Component-styles.css'
import { Link } from 'react-router-dom'

const Unifyforwho = () => {
  return (
    <div className="">
      <h2 className="text-gray-900 text-center text-2xl font-medium font-inter leading-loose mb-14">
        What we offer
      </h2>

      <div className="flex items-center justify-center mb-5 flex-wrap">
        <a
          href="https://goverticul.io/"
          className="py-5 px-10 animate__animated animate__fadeInUp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="md:w-[400px] p-5 bg-white rounded-2xl border border-gray-300">
            <div className="flex items-center relative py-[50px]  justify-center">
              <img
                className="w-11 h-11 rounded-full shadow absolute top-[80px] hidden md:block left-[30px] animate__animated animate__bounce animate__slower animate__infinite"
                src="/cbuimage1.svg"
                alt="man 3"
              />

              <img
                className="w-16 h-16 rounded-full shadow absolute bottom-[30px] left-[30px] hidden md:block animate__animated animate__pulse animate__slower animate__infinite"
                src="/cbuimage2.svg"
                alt="woman 1"
              />
              <img
                className="w-[34px] h-[34px] rounded-full shadow absolute top-[50px] right-[30px] hidden md:block animate__animated animate__pulse animate__slower animate__infinite"
                src="/cbuimage3.svg"
                alt="man 2"
              />
              <img
                className="w-[62px] h-[62px] rounded-full shadow absolute bottom-[30px] right-[30px] hidden md:block animate__animated animate__swing animate__slower animate__infinite"
                src="/cbuimage4.svg"
                alt="man 1"
              />
              <div className="md:w-[188px] w-[100px] h-[100px] md:h-[188px] flex items-center justify-center bg-violet-50 rounded-full shadow animate__animated animate__slower animate__zoomIn">
                <div className="hidden md:block">
                  <img
                    src={verticul_logo}
                    alt="verticul"
                    className="w-[6rem] h-[6rem]"
                  />
                </div>
                <div className="block md:hidden">
                  <img
                    src={verticul_logo}
                    alt="verticul"
                    className="w-10 h-10"
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-indigo-500 text-lg font-medium font-inter leading-7">
                Verticul
              </h3>
              <span className="w-[442px] text-slate-700 text-base font-normal font-inter leading-normal">
                Get access to relevant content, practical courses and a vibrant
                community that takes you from job-seeker to head-hunted!
              </span>
            </div>
          </div>
        </a>

        <a
          href="https://plearnty.unifyedu.ng/"
          className="py-5 px-10 animate__animated animate__fadeInUp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-full md:w-[400px] p-5 bg-white rounded-2xl border border-gray-300">
            <div className="flex items-center relative py-[50px]  justify-center">
              <img
                className="w-11 h-11 rounded-full shadow absolute top-[80px] left-[30px] hidden md:block animate__animated animate__bounce animate__slower animate__infinite"
                src="/36oimage1.svg"
                alt="woman 1"
              />

              <img
                className="w-16 h-16 rounded-full shadow absolute bottom-[30px] left-[30px] hidden md:block animate__animated animate__pulse animate__infinite"
                src="/360Image2.svg"
                alt="man 1"
              />
              <img
                className="w-[34px] h-[34px] rounded-full shadow absolute top-[50px] right-[30px] hidden md:block animate__animated animate__heartBeat animate__slow animate__infinite"
                src="/360Image3.svg"
                alt="woman 2"
              />
              <img
                className="w-[62px] h-[62px] rounded-full shadow absolute bottom-[30px] right-[30px] hidden md:block animate__animated animate__swing animate__slower animate__infinite"
                src="/360Image4.svg"
                alt="man 2"
              />
              <div className="md:w-[188px] w-[100px] h-[100px] md:h-[188px] flex items-center justify-center bg-violet-50 rounded-full shadow animate__animated animate__slower animate__zoomIn">
                <div className="hidden md:block">
                  <img src="/unify360.png" alt="" />
                </div>
                <div className="block md:hidden">
                  <img src="/360mini.png" alt="" />
                </div>
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-indigo-500 text-lg font-medium font-inter leading-7">
                Plearnty By Unify{' '}
              </h3>
              <span className="w-[442px] text-slate-700 text-base font-normal font-inter leading-normal">
                By taking simple quizzes and earning tokens, undergraduates in
                Nigeria make REAL CASH--enough to cover their school fees and
                take care of what truly matters.
              </span>
            </div>
          </div>
        </a>
      </div>

      <div className="p-10">
        <div className="w-full h-max overflow-hidden p-10 mt-10 relative bg-indigo-500 rounded-[32px]">
          <h2 className="text-violet-50 font-bold text-2xl">Verticul</h2>

          <div className="w-full md:w-[511px] ">
            <h2 className="w-full text-violet-50 pt-5 md:pt-10 text-2xl md:text-5xl font-semibold font-inter md:leading-[60px]">
              How High Can You Go?{' '}
            </h2>
            <div className="w-full text-violet-50 my-1.5 text-base md:text-l font-normal font-inter leading-[30px]">
              Become 5x more employable with our in-demand courses and a
              thriving community of Pals who work at top organizations.
            </div>
          </div>
          <div className="hidden md:block w-[631px] h-[631px] left-[687px] top-[297px] absolute rounded-full border border-indigo-300 " />
          <div className="w-[631px] hidden md:block h-[631px] left-[1019px] top-[116px] absolute rounded-full border border-indigo-300" />
          <div className="w-[631px] h-[631px] hidden md:block left-[653px] top-[259px] absolute rounded-full border border-indigo-300" />
          <img
            className="hidden md:block w-[328px] h-[382px] left-[805px] top-[-4px] absolute rounded-bl-[224px] rounded-br-[224px]"
            src="/upskillImg2.png"
            alt=""
          />
          <a
            href="https://goverticul.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="text-violet-50 text-xs md:text-sm font-medium font-inter leading-7 flex items-center gap-1.5 cursor-pointer pb-0 md:pb-20 pt-10">
              Start here
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Unifyforwho
