import React from 'react';

const products = () => {
    return (
        <div className='pt-20'>
            products
        </div>
    );
};

export default products;