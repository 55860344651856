import React from 'react'
import '../Components/Component-styles.css'
import { FaAngleDown } from 'react-icons/fa'

const Faqs = () => {
  const [faqs, setFaqs] = React.useState([
    {
      id: 1,
      isOpen: false,
      question: 'What is Verticul?',
      answer:
        'Verticul is an online learning platform that allows anyone to watch, listen and learn from our everyday STARS or Subject matter expert The goal is to aid prospective users to learn from powerfully talented individuals using brilliant storytelling, interactivity, and gamification.',
    },
    {
      id: 2,
      isOpen: false,
      question: 'Who is Verticul for?',
      answer:
        'The platform is available to anyone willing to improve a passion, learn a trade, advance a career or get inspiration from the industry best.',
    },
    {
      id: 3,
      isOpen: false,
      question: 'How do I create a Verticul account?',
      answer: `To get started with your account, follow these steps:
    1. Log on to Verticul’s Website – https://goverticul.io/
    2. Fill in your details in the sign-up form
    3. Agree to the terms and conditions
    4. Click on "Sign up"

    That's it, you're all set!`,
    },

    {
      id: 4,
      isOpen: false,
      question: 'What is Plearnty by Unify?',
      answer:
        "Plearnty by Unify is an educational platform that combines learning with earning. Through engaging quizzes, University undergraduates can compete in academic challenges, earn Plearnty tokens, and convert them into cash rewards. Plearnty aims to support students in paying their school fees and earning extra streams of income, while gaining valuable skills and knowledge. It's designed to make education rewarding in every sense!",
    },
    {
      id: 5,
      isOpen: false,
      question: 'How often can I participate in quizzes?',
      answer:
        'New quizzes are added regularly, allowing you to participate as often as you like.',
    },
    {
      id: 6,
      isOpen: false,
      question: 'Is there a limit to how much I can earn?',
      answer:
        "There's no limit to how much you can earn. The more quizzes you take part in, the higher your earnings.",
    },
  ])

  const toggle = (id) => {
    setFaqs(
      faqs.map((item) => {
        if (item.id === id) {
          item.isOpen = !item.isOpen
        } else {
          item.isOpen = false
        }
        return item
      })
    )
  }
  return (
    <div className="py-20">
      <div className="relative mt-2 mb-5">
        <p className="title-feat-faq"></p>
      </div>
      <div className="absolute hidden md:block -translate-y-20 right-36 border-r-[3px] border-dashed border-[#F1EBE3] h-[1500px]"></div>
      <div className="absolute hidden md:block -translate-y-20 left-36  border-r-[3px] border-dashed border-[#F1EBE3] h-[1500px]"></div>

      <p className="mb-14 -6 fomt-medium text-3xl text-center">FAQs</p>

      <div className="md:w-8/12 mx-auto w-full px-5">
        {faqs.map((item) => (
          <div
            key={item.id}
            onClick={() => {
              toggle(item.id)
            }}
            className="cursor-pointer border-b mb-2"
          >
            <div className="flex justify-between items-center py-2 ">
              <div className="font-medium"> {item.question} </div>
              <div>
                <FaAngleDown
                  className={`${
                    item.isOpen ? 'transform rotate-180 duration-500' : ''
                  }`}
                />
              </div>
            </div>
            {
              <div className={`${item.isOpen ? 'flex' : 'hidden'} py-5`}>
                {item.answer}
              </div>
            }
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faqs
